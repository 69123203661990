<template>
  <div class="d-flex justify-center box">
    <div class="d-flex align-center">
      <v-card width="300" :color="statusState.isLoading ? 'transparent' : ''" :elevation="statusState.isLoading ? 0 : ''">
        <v-progress-circular v-if="statusState.isLoading" indeterminate color="primary" size="32" class="center"/>
        <v-form v-else :class="statusState.isLoading ? 'o-0' : ''" @submit.prevent="login">
          <v-toolbar elevation="0" color="transparent" dense>
            <v-toolbar-title>Войти</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-alert v-if="errorMessage!==null" dense type="error" class="caption">{{errorMessage}}</v-alert>
            <v-text-field v-model="phone" v-mask="'+# (###) ###-##-##'" class="mb-4" label="Логин" outlined dense hide-details="auto"/>
            <v-text-field v-model="pass" type="password" label="Пароль" outlined dense hide-details="auto"/>
          </v-card-text>
          <v-card-actions>
            <v-spacer/>
            <v-btn text color="primary" type="submit" :loading="loginState.isLoading">Войти</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<script>

import State from "@/plugins/state";
import Api from "@/Api";

export default {
  data: () => ({
    statusState: new State(),
    loginState: new State(),
    errorMessage: null,
    phone:null,
    pass:null,
  }),
  mounted() {
    if (this.$store.state.user===null){
      this.statusState.stateLoading();
      Api.service.user.status((d)=>{
        if (typeof d.id!=='undefined'){
          this.statusState.stateSuccess();
          this.$store.state.user = d;
          this.$router.push('/event/');
        }
        if (typeof d.message!=='undefined')
          this.errorMessage = d.message;
        this.statusState.stateError();
      }, ()=>{
        this.statusState.stateError();
      })
    }
  },
  methods: {
    login(){
      this.loginState.stateLoading();
      Api.service.user.login(this.phone, this.pass, (d)=>{
        if (typeof d.id!=='undefined'){
          this.loginState.stateSuccess();
          this.$store.state.user = d;
          this.$router.push('/event/');
        }
        if (typeof d.message!=='undefined'){
          this.loginState.stateError();
          this.errorMessage = d.message;
        }
      }, ()=>{
        this.statusState.stateError();
      })
    }
  }
}
</script>


<style type="scss" scoped>
.box{
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.center{
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -16px 0 0 -16px;
}
</style>